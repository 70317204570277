import React from 'react'
import "./header.css"
import CTA from './CTA'
import ME from '../../assets/mep.png'
import Headersocials from './headersocials'




const header = () => {
  return (
    <header id='home'>
      <div className="container header__container">
      <h5>Hello I'm</h5>
      <h1>Nani</h1>
      <h5 className="text-light">Fullstack Developer</h5>
      <CTA />
      <Headersocials />
      <div className="me">
        <img src={ME} alt="Nani pic" />
      </div>

      <a href="#contact" className='scroll__down'>Scroll Down</a>





      </div>
    </header>
  )
}

export default header