import React from 'react'
import { BsLinkedin } from "react-icons/bs";
import { FaGithub } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";




const headersocials = () => {
  return (
    <div className="header__social">
        <a href="https://linkedin.com" target='null'><BsLinkedin /></a>
        <a href="https://github.com/Nani500411" target='null'><FaGithub /></a>
        <a href="https://www.instagram.com/nani_500411/" target='null'><FaInstagram /></a>
    </div>
  )
}

export default headersocials