import React from 'react';
import './about.css';
import ME from '../../assets/mep.png';
import { FiAward, FiUsers } from 'react-icons/fi';
import { VscFolderLibrary } from 'react-icons/vsc';

function About() {
  return (
    <section id="about">
      <h5>Get To Know</h5>
      <h2>About Me</h2>
      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="aboutimage" />
          </div>
        </div>
        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FiAward className="about__exp" />
              <h5>Learning</h5>
              <small>Passionate about continuous learning</small>
            </article>

            <article className="about__card">
              <FiUsers className="about__exp" />
              <h5>Projects</h5>
              <small>Exploring and working on various projects</small>
            </article>

            <article className="about__card">
              <VscFolderLibrary className="about__exp" />
              <h5>Exploration</h5>
              <small>Curious and enthusiastic about new technologies</small>
            </article>
          </div>
          <p>
            Hi, I'm Nani, a self-taught individual with a passion for exploring and creating. Although I don't
            have formal experience, I am dedicated to continuous learning and have been working on various projects to
            enhance my skills. Let's embark on this journey together and turn ideas into exciting digital experiences!
          </p>

          <a href="#contact" className="btn btn-primary">
            Let's Talk
          </a>
        </div>
      </div>
    </section>
  );
}

export default About;
