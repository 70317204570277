import React from 'react'
import './experience.css'
import { BsShieldFillCheck } from "react-icons/bs";

const experience = () => {
  return (
    <section id='experience'>
      <h5>What Skills I Have</h5>
      <h2>My Experience</h2>

      <div className="container experience__container">
        <div className="experience__frontend">
          <h3>Frontend Development</h3>
          <div className="experience__content">
            <article className='experience__details'>
              <BsShieldFillCheck className='experience__details-icon' />
              <div> <h4>HTML</h4>
                <small className='text-light'>Experienced</small>
              </div>

            </article>
            <article className='experience__details'>
              <BsShieldFillCheck className='experience__details-icon' />
              <div>
                <h4>CSS</h4>
                <small className='text-light'>Experienced</small>

              </div>
            </article>
            <article className='experience__details'>
              <BsShieldFillCheck className='experience__details-icon' />
              <div>
                <h4>JavaScript</h4>
                <small className='text-light'>Experienced</small>

              </div>

            </article>
            <article className='experience__details'>
              <BsShieldFillCheck className='experience__details-icon' />
              <div>
                <h4>Bootstrap</h4>
                <small className='text-light'>Experienced</small>
              </div>

            </article>
            <article className='experience__details'>
              <BsShieldFillCheck className='experience__details-icon' />
              <div>
                <h4>Tailwind</h4>
                <small className='text-light'>Experienced</small>
              </div>

            </article>

            <article className='experience__details'>
              <BsShieldFillCheck className='experience__details-icon' />
              <div>
                <h4>React</h4>
                <small className='text-light'>Experienced</small>
              </div>

            </article>


          </div>
        </div>
        <div className="experience__backend">
          <h3>Backend Development</h3>
          <div className="experience__content">
            <article className='experience__details'>
              <BsShieldFillCheck className='experience__details-icon' />
              <div>
                <h4>Node Js</h4>
                <small className='text-light'>Experienced</small>
              </div>

            </article>
            <article className='experience__details'>
              <BsShieldFillCheck className='experience__details-icon' />
              <div>
                <h4>Django</h4>
                <small className='text-light'>Experienced</small>
              </div>

            </article>
            <article className='experience__details'>
              <BsShieldFillCheck className='experience__details-icon' />
              <div>
                <h4>Git</h4>
                <small className='text-light'>Experienced</small>
              </div>

            </article>
            <article className='experience__details'>
              <BsShieldFillCheck className='experience__details-icon' />
              <div>
                <h4>MySQL</h4>
                <small className='text-light'>Experienced</small>
              </div>

            </article>
            <article className='experience__details'>
              <BsShieldFillCheck className='experience__details-icon' />
              <div>
                <h4>Python</h4>
                <small className='text-light'>Experienced</small>
              </div>

            </article>




          </div>
        </div>




        <div className="experience__backend">
          <h3>Digital Marketing Services</h3>
          <div className="experience__content">
            <article className='experience__details'>
              <BsShieldFillCheck className='experience__details-icon' />
              <div>
                <h4>Comprehensive Digital Marketing Strategies</h4>
                <small className='text-light'>Exerienced</small>
              </div>

            </article>
            <article className='experience__details'>
              <BsShieldFillCheck className='experience__details-icon' />
              <div>
                <h4>Search Engine Optimization (SEO)</h4>
                <small className='text-light'>Experienced</small>
              </div>

            </article>
            <article className='experience__details'>
              <BsShieldFillCheck className='experience__details-icon' />
              <div>
                <h4>Social Media Marketing</h4>
                <small className='text-light'>Experienced</small>
              </div>

            </article>
            <article className='experience__details'>
              <BsShieldFillCheck className='experience__details-icon' />
              <div>
                <h4>Targeted Audience Reach</h4>
                <small className='text-light'>Experienced</small>
              </div>

            </article>
            <article className='experience__details'>
              <BsShieldFillCheck className='experience__details-icon' />
              <div>
                <h4>Analytics and Reporting</h4>
                <small className='text-light'>Experienced</small>
              </div>

            </article>
            
            <article className='experience__details'>
              <BsShieldFillCheck className='experience__details-icon' />
              <div>
                <h4>Continuous Optimization and Monitoring:</h4>
                <small className='text-light'>Experienced</small>
              </div>

            </article>



          </div>
        </div>






      </div>
    </section>
  )
}

export default experience