import React, { useRef, useState } from 'react';
import './contact.css';
import { MdOutlineEmail } from "react-icons/md";
import { MdOutlinePhone } from "react-icons/md";

import { FaWhatsapp } from "react-icons/fa";

import emailjs from '@emailjs/browser';

const Contact = () => {
  const form = useRef();
  const [isMessageSent, setMessageSent] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_fsuezvr', 'template_s2eeb9r', form.current, 'UuCMXAwD35sqt070r')
      .then((result) => {
        console.log(result.text);
        // Set the state to true when the email is sent successfully
        setMessageSent(true);
        // Display a pop-up alert
        alert("Message sent successfully!");
        // Reset the form
        form.current.reset();
      })
      .catch((error) => {
        console.log(error.text);
        alert("Something went wrong!");
      });
  };

  return (
    <section id='contact'>
      <h5>Get In Touch</h5>
      <h2>Contact</h2>
      <div className="container contact__container">
        <div className="contact__options">
        <article className="contact__option">
  <MdOutlinePhone className='contact__option-icon' />
  <h4>Phone</h4>
  <h5>9381788941</h5>
  <a href="tel:+919381788941">Call Now</a>
</article>


<article className="contact__option">
  <FaWhatsapp className='contact__option-icon' />
  <h4>WhatsApp</h4>
  <h5>Chat</h5>
  <a href="https://wa.me/9381788941?text=Hi,%20I%20saw%20your%20contact%20details%20on%20your%20website%20and%20I%20would%20like%20to%20chat">Send a message</a>
</article>



          <article className="contact__option">
            <MdOutlineEmail className='contact__option-icon'/>
            <h4>Email</h4>
            <h5>neelakanta060@gmail.com</h5>
            <a href="mailto:neelakanta060@gmail.com">Send a message</a>
          </article>
        </div>

        <form ref={form} onSubmit={sendEmail}>
          <input
            type="text"
            name='name'
            placeholder='Your Full Name'
            required
          />
          <input
            type="email"
            name='email'
            placeholder='Your Email'
            required
          />
          <textarea
            name="message"
            rows="7"
            placeholder='Your Message'
            required
          ></textarea>
          <button type='submit' className='btn btn-primary'>Send Message</button>
        </form>
        {isMessageSent && (
          <div className="modal">
            <div className="modal-content">
              <span className="close" onClick={() => setMessageSent(false)}>&times;</span>
              <p>Message sent successfully!</p>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default Contact;
