import React from 'react'
import './nav.css'
import { IoHome } from "react-icons/io5";
import { FaRegUser } from "react-icons/fa";
import { MdMenuBook } from "react-icons/md";
import { RiServiceLine } from "react-icons/ri";
import { BiMessageSquareDetail } from "react-icons/bi";
import { useState } from 'react';

const Nav = () => {
  const [activeNav, setActiveNav] = useState('#');
  return (
    <nav>
      <a href="#home" onClick={() => setActiveNav('#home')} className={ activeNav === '#home' ? 'active': ''}><IoHome /></a>
      <a href="#about" onClick={() => setActiveNav('#about')}className={activeNav === '#about' ? 'active': ''}><FaRegUser /></a>
      <a href="#experience" onClick={() => setActiveNav('#experience')}className={activeNav === '#experience' ? 'active': ''}><MdMenuBook /></a>
      <a href="#services" onClick={() => setActiveNav('#services')}className={activeNav === '#services' ? 'active': ''}><RiServiceLine /></a>
      <a href="#contact" onClick={() => setActiveNav('#contact')}className={activeNav === '#contact' ? 'active': ''}><BiMessageSquareDetail /></a>
    </nav>
  )
}

export default Nav