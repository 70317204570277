import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/portfolioimg1.png'



/*  portfolio data    */
const data = [
  {
    id: 1,
    image: IMG1,
    title: ' karthikeya travels website',
    github: 'https://github.com',
    demo: 'https://karthikeyatravelstirupati.com'
  },
  {
    id: 2,
    image: IMG1,
    title: ' karthikeya travels website',
    github: 'https://github.com',
    demo: 'https://karthikeyatravelstirupati.com'
  },
  {
    id: 3,
    image: IMG1,
    title: ' karthikeya travels website',
    github: 'https://github.com',
    demo: 'https://karthikeyatravelstirupati.com'
  },
  {
    id: 4,
    image: IMG1,
    title: ' karthikeya travels website',
    github: 'https://github.com',
    demo: 'https://karthikeyatravelstirupati.com'
  },
  {
    id: 5,
    image: IMG1,
    title: ' karthikeya travels website',
    github: 'https://github.com',
    demo: 'https://karthikeyatravelstirupati.com'
  },

]






const portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>My Recent Work</h5>
      <h2> Portfolio</h2>
      <div className="container portfolio__container">

        <h2>I will update my projects as soon as possible</h2>
        {/* {
          data.map(({id, image, title, github, demo}) => {
            return (
              <article key={id} className="portfolio__item">
                 <div className="portfolio__item-image">
                   <img src={image} alt={title} />
                 </div>
                 <h3>{title}</h3>
                 <div className="portfolio__item-cta">
                  <a href={github} className='btn' target='noopener'>Github</a>
                 <a href={demo} className='btn btn-primary' target='noopener'>Live Demo</a>
                 </div>
               </article>

            )
          })
        } */}
        
        
      </div>
    </section>
  )
}

export default portfolio