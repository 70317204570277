import React from 'react';
import './services.css';
import { FaCheck } from 'react-icons/fa';

const Services = () => {
  return (
    <section id="services">
      <h5>What I Offer</h5>
      <h2>Services</h2>
      <div className="container services__container">
        {/* UI/UX Design */}
        <article className="service">
          <div className="service__head">
            <h3>UI/UX Design</h3>
          </div>
          <ul className="service__list">
            <li>
              <FaCheck className="service__list-icon" />
              <p>Creative and User-Centric Design</p>
            </li>
            <li>
              <FaCheck className="service__list-icon" />
              <p>Responsive and Modern Interfaces</p>
            </li>
            <li>
              <FaCheck className="service__list-icon" />
              <p>Usability Testing and Iterative Design</p>
            </li>
            <li>
              <FaCheck className="service__list-icon" />
              <p>Accessible Design for All Users</p>
            </li>
            <li>
              <FaCheck className="service__list-icon" />
              <p>Consistent Branding and Visual Identity</p>
            </li>
            <li>
              <FaCheck className="service__list-icon" />
              <p>Typography and Color Psychology</p>
            </li>
            <li>
              <FaCheck className="service__list-icon" />
              <p>Animation and Microinteractions</p>
            </li>
            <li>
              <FaCheck className="service__list-icon" />
              <p>Information Architecture and Navigation</p>
            </li>
            <li>
              <FaCheck className="service__list-icon" />
              <p>Collaboration with Developers for Seamless Execution</p>
            </li>
          </ul>
        </article>

        {/* Web Development */}
        <article className="service">
          <div className="service__head">
            <h3>Web Development</h3>
          </div>
          <ul className="service__list">
            <li>
              <FaCheck className="service__list-icon" />
              <p>Full-Stack Development (React, Django)</p>
            </li>
            <li>
              <FaCheck className="service__list-icon" />
              <p>Responsive Web Design for Cross-Device Compatibility</p>
            </li>
            <li>
              <FaCheck className="service__list-icon" />
              <p>Effective Database Management (MySQL, MongoDB)</p>
            </li>
            <li>
              <FaCheck className="service__list-icon" />
              <p>API Integration for Enhanced Functionality</p>
            </li>
            <li>
              <FaCheck className="service__list-icon" />
              <p>Version Control with Git for Collaboration</p>
            </li>
            <li>
              <FaCheck className="service__list-icon" />
              <p>Security Practices for Web Application Safety</p>
            </li>
            <li>
              <FaCheck className="service__list-icon" />
              <p>Performance Optimization and Load Time Reduction</p>
            </li>
            <li>
              <FaCheck className="service__list-icon" />
              <p>Cross-Browser Compatibility Testing</p>
            </li>
            <li>
              <FaCheck className="service__list-icon" />
              <p>Scalable Architectures for Future Growth</p>
            </li>
            <li>
              <FaCheck className="service__list-icon" />
              <p>Continuous Learning and Adaptation to Latest Trends</p>
            </li>
          </ul>
        </article>

        {/* Content Creation */}
        <article className="service">
          <div className="service__head">
            <h3>Content Creation</h3>
          </div>
          <ul className="service__list">
            <li>
              <FaCheck className="service__list-icon" />
              <p>Strategic Content Planning and Execution</p>
            </li>
            <li>
              <FaCheck className="service__list-icon" />
              <p>Compelling Copywriting for Effective Communication</p>
            </li>
            <li>
              <FaCheck className="service__list-icon" />
              <p>SEO Optimization for Improved Discoverability</p>
            </li>
            <li>
              <FaCheck className="service__list-icon" />
              <p>Multimedia Integration (Images, Videos, Infographics)</p>
            </li>
            <li>
              <FaCheck className="service__list-icon" />
              <p>Social Media Content Tailored for Platforms</p>
            </li>
            <li>
              <FaCheck className="service__list-icon" />
              <p>Consistent Content Calendar for Timely Delivery</p>
            </li>
            <li>
              <FaCheck className="service__list-icon" />
              <p>Audience Engagement Through Interactive Content</p>
            </li>
            <li>
              <FaCheck className="service__list-icon" />
              <p>Analytics Utilization for Data-Driven Content Decisions</p>
            </li>
            <li>
              <FaCheck className="service__list-icon" />
              <p>Consistent Brand Voice and Tone Across Channels</p>
            </li>
            <li>
              <FaCheck className="service__list-icon" />
              <p>Adaptability to Evolving Content Trends</p>
            </li>
          </ul>
        </article>
      </div>
    </section>
  );
};

export default Services;
