import React from 'react'
import "./testimonials.css"
import AVTR1 from '../../assets/photo_2023-09-17_09-14-41-fotor-2023091791859.png'


// import Swiper core and required modules
import { Pagination, } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';

import 'swiper/css/pagination';





const data = [
  {
    avatar: AVTR1,
    name: 'Nani',
    review: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. totam, inventore quae nobis delectus at doloribus deserunt magni enim neque,',

  },
  {
    avatar: AVTR1,
    name: 'micky',
    review: 'Lorem ipsum dolor sit,  pariatur totam, inventore quae nobis delectus at doloribus deserunt magni enim neque,',
    
  },
  {
    avatar: AVTR1,
    name: 'moon',
    review: 'Lorem ipsum dolor sit,  ab obcaecati soluta aspernatur pariatur totam, inventore quae nobis delectus at doloribus deserunt magni enim neque,',
    
  },
  {
    avatar: AVTR1,
    name: 'maker',
    review: 'Lorem ipsum  praesentium ab obcaecati soluta aspernatur pariatur totam, inventore quae nobis delectus at doloribus deserunt magni enim neque,',
    
  }
]





const testimonials = () => {
  return (
    <section id='testimonials'>
      <h5>Review from clients</h5>
      <h2>Testimonials</h2>

      <Swiper className="container testimonials__container"
      // install Swiper modules
      modules={[ Pagination,]}
      spaceBetween={40}
      slidesPerView={1}
    
      pagination={{ clickable: true }}
      >
        {
          data.map(({avatar,name,review},index) => {
            return (
              <SwiperSlide key={index} className="testimonials">
          <div className="client__avatar">
            <img src={avatar} alt="" />
           
          </div>
          <h5 className='client__name'>{name}</h5>
            <small className="client__review">{review}</small>

        </SwiperSlide>


            )
          })
        }
        

        




        </Swiper>
    </section>

     
  )
}

export default testimonials